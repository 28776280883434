import React from "react"
import * as Icon from "react-feather"
import { graphql } from "gatsby"

import CaseDetail from "../../components/Shared/casedetails"

const seoTitle = "Opinionated Terraform Modules & Blueprints"
const pageTitle = "Opinionated Terraform Modules & Blueprints"
const subTitle = "Cloud Infrastructure "
const para =`Our expertise lies in comprehending the distinct security requirements and architectural considerations specific to large, regulated institutions.We expedite client migrations and cloud adoption by implementing opinionated, service-oriented architectures.We collaborate with essential companies such as HashiCorp to bridge gaps that may hinder customers from transitioning to the Cloud.Our blended infrastructure and DataOps workflows cater to the infrastructure and data teams of our clients.We encourage and enable client teams to utilize cloud resources while adhering to corporate governance practices.`
const blockquote = `"It is crucial to view migration as a transformative process that requires the
alignment among application, business, DevOps, and migration teams towards a
common goal. Emphasizing this as a transformational effort is required."`

const cardItems = [
  {
    id: 1,
    heading: `How do we deliver when we are building the factory?`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          The process of migration is actually a process of transformation.
          Application teams, business owners, foundation/capability teams, and
          the migration team need to align on the common goal how to
          maximize the benefits of the new system while managing timeline
          expectations.
        </li>
      </>
    ),
  },
  {
    id: 2,
    heading: `Transformation Factory Pattern Defined`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          <strong>The Factory:{" "}</strong>The Foundations (networking, security,
          identity, observability, and DevOps pipeline) is the common framework
          upon which all shared capabilities (Kubernetes, nTier architectures,
          data streaming, etc.) will be built.
        </li>
        <li>
          <Icon.Square />
          <strong> The Assembly Line:{" "}</strong>The initial validation of the
          Foundations using a greenfield, trailblazer application to evaluated
          the overall system and make any necessary changes or improvements
          before full-scale migrations begin.
        </li>
        <li>
          <Icon.Square />
          <strong>Retooling and Run:{" "}</strong>Alignment of capabilities to the
          architecture and business priorities to create distinct migration
          “waves”. Each “wave” builds upon the previous in an opinionated,
          flexible, and reusable manner.
        </li>
      </>
    ),
  },
]

const BlogDetails = ({ data }) => (
  <CaseDetail
    seoTitle={seoTitle}
    pageTitle={pageTitle}
    cardItems={cardItems}
    subTitle={subTitle}
    para={para}
    blockquote={blockquote}
    images={data}
  />
)
export const query = graphql`
  query AutomationQuery {
    casedetailsJson(slug: { eq: "opinionated-terraform-modules-and-blueprints" }) {
      images {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      slug
    }
  }
`
export default BlogDetails
